<template>
  <div class="float_button" @click="show">
    <div
      @click="onBtnClicked"
      ref="floatButton"
      :class="float_info"
      :style="{ left: left + 'px', top: top + 'px' }"
    >
      <img src="../../assets/winningRecord/rukou.png" alt="" srcset="" />
      <!-- <span class="text">{{ text }}</span> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clientWidth: 0,
      clientHeight: 0,
      timer: null,
      currentTop: 0,
      left: 303,
      top: 150.75,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      float_info: "float_infoOne",
    };
  },
  props: {
    text: {
      // 按钮文本内容
      type: String,
      default: "记录",
    },
    itemWidth: {
      // 悬浮按钮宽度
      type: Number,
      default: 144,
    },
    itemHeight: {
      // 悬浮按钮高度
      type: Number,
      default: 122,
    },
    gapWidth: {
      // 距离左右两边距离
      type: Number,
      default: 0,
    },
    coefficientHeight: {
      // 从上到下距离比例
      type: Number,
      default: 0.25,
    },
  },
  created() {},
  methods: {
    show() {
      this.$emit("click");
    },
    onBtnClicked() {
      this.$emit("onFloatBtnClicked");
    },
    handleScrollStart() {
      this.timer && clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleScrollEnd();
      }, 300);
      this.currentTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (this.left > this.clientWidth / 2) {
        this.left = this.clientWidth - this.itemWidth / 2;
      } else {
        this.left = -this.itemWidth / 2;
      }
    },
    handleScrollEnd() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop === this.currentTop) {
        if (this.left > this.clientWidth / 2) {
          this.left = this.clientWidth - this.itemWidth - this.gapWidth;
        } else {
          this.left = this.gapWidth;
        }
        clearTimeout(this.timer);
      }
    },
    defferScroll: function (event) {
      event.preventDefault();
    },
  },
  mounted() {
    let windowWidth = this.windowWidth,
      windowHeight = this.windowHeight,
      domWidth = this.$refs.floatButton.clientWidth;
    this.clientWidth = document.documentElement.clientWidth;
    this.clientHeight = document.documentElement.clientHeight;
    this.left = this.clientWidth - domWidth - this.gapWidth;
    this.top = this.clientHeight * this.coefficientHeight;
    this.$nextTick(() => {
      const floatButton = this.$refs.floatButton;
      floatButton.addEventListener("touchstart", () => {
        floatButton.style.transition = "none";
      });

      // 在拖拽的过程中，组件应该跟随手指的移动而移动。
      floatButton.addEventListener("touchmove", (e) => {
        if (e.targetTouches.length === 1) {
          // 一根手指
          let touch = e.targetTouches[0];
          if (touch.clientX < 0) {
            this.left = 0;
          }
          if (touch.clientX >= windowWidth) {
            this.left = windowWidth - 30;
          }
          if (touch.clientX < windowWidth && touch.clientX > 0) {
            this.left = touch.clientX - 20;
          }
          if (touch.clientY < 0) {
            this.top = 0;
          }
          if (touch.clientY >= windowHeight) {
            this.top = windowHeight - 60;
          }
          if (touch.clientY > 0 && touch.clientY < windowHeight) {
            this.top = touch.clientY - 25;
          }
          this.float_info = "float_infoMove";
          document.body.addEventListener("touchmove", this.defferScroll, {
            passive: false,
          });
          document.body.addEventListener("wheel", this.defferScroll, {
            passive: false,
          });
        }
      });
      // 拖拽结束以后，重新调整组件的位置并重新设置过度动画。
      floatButton.addEventListener("touchend", () => {
        floatButton.style.transition = "all 0.3s";
        if (this.left > document.documentElement.clientWidth / 2) {
          this.left = document.documentElement.clientWidth - domWidth;
          this.float_info = "float_infoOne";
        } else {
          this.left = 0;
          this.float_info = "float_infoTwo";
        }
        document.body.removeEventListener("touchmove", this.defferScroll, {
          passive: false,
        });
        document.body.removeEventListener("wheel", this.defferScroll, {
          passive: false,
        });
      });
    });
  },
  beforeDestroy() {
    // 添加监听页面滚动
    window.removeEventListener("scroll", this.handleScrollStart);
  },
  destroyed() {},
};
</script>
<style>
.float_infoOne,
.float_infoTwo {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  color: #666666;
  transition: all 0.3s;
  position: fixed;
  bottom: 436px;
  right: 0;
  width: 144px;
  height: 122px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
  background: rgba(256, 256, 256, 0.4);
  border-radius: 60px 0 0 60px;
  cursor: pointer;
}

.float_infoMove {
  /* box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1); */
  /* color: #666666; */
  transition: all 0.3s;
  position: fixed;
  bottom: 436px;
  right: 0;
  width: 144px;
  height: 122px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 999;
  /* background: rgba(256, 256, 256, 0.5);
    border-radius: 60px 0 0 60px; */
  cursor: pointer;
}

.float_infoTwo {
  border-radius: 0 60px 60px 0;
  align-items: flex-start;
}

.text {
  font-size: 24px;
  color: #fff;
}

.float_infoOne img,
.float_infoTwo img,
.float_infoMove img {
  width: 110px;
  height: 92px;
  margin: 0 6px;
}
</style>
