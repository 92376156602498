<template>
  <div class="winningRecordBg" v-show="recordShow">
    <div class="winningRecordContent">
      <img src="../../assets/winningRecord/bg.png" alt="" />
      <div class="recordList">
        <div>
          <h2>派奖记录</h2>
          <ul class="recordContent">
            <li v-for="(item, index) in recordList.prizeLogLists" :key="index">
              <div>
                <p class="prizeName">{{ item.prizeName }}</p>
                <p class="prizeTime">中奖时间：{{ item.createTime }}</p>
              </div>
              <!-- //(注:电子奖返回状态 -1 已过期,1为详情,2
              为我方api链接为待领取,5为我方api链接 为已领取)) -->
              <div v-show="false">
              <div
                class="notClaimed"
                v-if="item.status == 1"
                @click="hrefUrl(item.skipUrl)"
                v-show="false"
              >
                详情
              </div>
              <div
                class="notClaimed"
                v-else-if="item.status == 2"
                @click="hrefUrl(item.skipUrl)"
              >
                领取
              </div>
              <div class="received" v-else-if="item.status == 5">已领取</div>
              <div class="received" v-else-if="item.status == -1">已过期</div>
              </div>
            </li>
            <li v-if="recordList.prizeLogLists.length == 0">
              <div>
                <p class="prizeName">暂无</p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h2>红包记录</h2>
          <ul class="recordContent">
            <li v-for="(item, index) in recordList.wealLogLists" :key="index">
              <div>
                <p class="prizeName">{{ item.prizeName }}</p>
                <p class="prizeTime">
                  中奖时间：{{ item.createTime | formatDate }}
                </p>
              </div>
              <div class="received" v-if="item.isGet == 1">已领取</div>
              <div
                class="notClaimed"
                v-if="item.isGet == 0"
                @click="receive(item.prizeId, item.id)"
              >
                未领取
              </div>
              <div class="received" v-if="item.isGet == 3">已失效</div>
              <!-- <div class="received">已领取</div> -->
            </li>
            <li v-if="recordList.wealLogLists.length == 0">
              <div>
                <p class="prizeName">暂无</p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h2>实物记录</h2>
          <ul class="recordContent">
            <li v-for="(item, index) in recordList.awardsLogLists" :key="index">
              <div>
                <p class="prizeName">{{ item.prizeName }}</p>
                <p class="prizeTime">
                  中奖时间：{{ item.createTime | formatDate }}
                </p>
              </div>
              <div class="received" v-if="item.isGet == '1'">已领取</div>
              <div
                class="notClaimed"
                v-else-if="item.isGet == '0'"
                @click="prizeHref(item.id)"
              >
                未领取
              </div>
              <div class="received" v-else-if="item.isGet == '3'">已失效</div>
            </li>
            <li v-if="recordList.awardsLogLists.length == 0">
              <div>
                <p class="prizeName">暂无</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="closeRecord" @click="closeRecord">
        <img src="../../assets/winningRecord/guanbi.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate } from "../../utils/date";
import { selectPrizeUrl } from "../../api/index.js";
import { receiveRedPacket } from "../../api/index.js";
import { journal } from "../../api/index.js";
export default {
  data() {
    return {
      // recordList: [
      //   {
      //     name: "派券记录"
      //   },
      //   {
      //     name: "红包记录"
      //   },
      //   {
      //     name: "实物奖记录"
      //   }
      // ]
      // recordShow: true
      // recordList: {
      //   prizeLogLists: [],
      //   wealLogLists: [],
      //   awardsLogLists: [],
      // },
    };
  },
  props: ["recordShow", "recordList"],
  methods: {
    closeRecord() {
      this.$emit("click");
      var maidian = {
        userVisit: "10",
      };
      journal(maidian);
    },
    prizeHref(id) {
      console.log(id);
      selectPrizeUrl({ drawId: id, drawLogId: id }).then((res) => {
        console.log(res);
        if (res != null) {
          window.location.href = res.data;
        }
      });
    },
    hrefUrl(url) {
      window.location.href = url;
    },
    receive(prizeId, drawLogId) {
      this.$router.push({
        path: "/redEnvelopes",
        query: {
          prizeId: prizeId,
          drawLogId: drawLogId,
        },
      });
    },
  },
  mounted() {
    console.log(this.recordList);
  },
  beforeDestroy() {},
  destroyed() {},
  filters: {
    formatDate(time) {
      let date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
};
</script>
<style>
.winningRecordBg {
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}

.winningRecordBg img {
  width: 100%;
}

.winningRecordContent {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.recordList {
  width: 400px;
  position: absolute;
  top: 300px;
  left: 125px;
  height: 540px;
  overflow: auto;
  padding: 0 50px;
}

.recordList h2 {
  font-size: 26px;
  font-weight: 700;
  color: #303030;
  line-height: 50px;
  padding-top: 10px;
}

.recordContent li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px dashed rgba(153, 153, 153, 0.2);
  padding: 10px 0;
}

.prizeName {
  color: #666;
  font-size: 24px;
}

.prizeTime {
  color: #999999;
  font-size: 16px;
}

.notClaimed {
  width: 84px;
  line-height: 36px;
  background: #ff7e00;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  flex-shrink: 0;
}

.received {
  width: 84px;
  line-height: 36px;
  background: #c2c2c2;
  border-radius: 4px;
  color: #fff;
  font-size: 18px;
  text-align: center;
}

.closeRecord {
  width: 46px;
  position: absolute;
  top: 50px;
  right: 50px;
}
</style>
