<template>
  <div class="noDataTips" v-show="tipsShow">
    <div class="noDataContent">
      <img src="../../assets/noPrize/tipsBg.png" alt="" />
      <div class="noDataDetails">
        <div class="noDataLogo">
          <img src="../../assets/noPrize/tipsLogo.png" alt="" />
        </div>
        <div class="noDataTipsButton">
          <img src="../../assets/noPrize/button.png" alt="" />
          <div class="noDataTipsText">我知道了</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["tipsShow"],
  created() {},
  methods: {},
  mounted() {},
  destroyed() {},
};
</script>
<style>
.noDataTips {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 3;
}
.noDataTips img {
  width: 100%;
}
.noDataContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.noDataDetails {
  position: absolute;
  top: 78px;
  left: 50%;
  transform: translateX(-50%);
}
.noDataLogo {
  width: 190px;
  margin: 0 auto;
}
.noDataTipsButton {
  width: 332px;
  position: relative;
  margin-top: 108px;
}
.noDataTipsText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -73%);
  font-size: 30px;
  font-weight: 500;
  color: #b56408;
}
</style>
