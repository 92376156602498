<template>
  <div class="box">
    <div
      class="turntableBox"
      v-bind:style="{
        backgroundImage: 'url(' + imgUrl + imgList.backgroundImg + ')',
      }"
      v-show="loading"
    >
      <!-- 转盘 -->
      <div class="outer">
        <img :src="imgUrl + imgList.prizePlateConfig.outerImg" alt="" />
        <div class="inner" id="turnUl">
          <img :src="imgUrl + imgList.prizePlateConfig.innnerImg" alt="" />
          <ul
            class="prize"
            :style="{ webkitTransform: 'rotate(' + -base + 'deg)' }"
          >
            <li
              v-for="(item, index) in prizeList"
              :key="index"
              :style="{ webkitTransform: 'rotate(' + -item.angle + 'deg)' }"
            >
              <img :src="imgUrl + item.prizeImg" alt="" />
            </li>
          </ul>
        </div>
        <div class="pointer" @click="startrotate()">
          <img :src="imgUrl + imgList.pointImg" alt="" />
        </div>
      </div>
      <!-- 问题咨询 -->
      <div class="question" @click="questionHref()">
        <img src="../../assets/wtzx.png" alt="" />
      </div>
      <!-- 中奖名单 -->
      <div class="winning">
        <img :src="imgUrl + imgList.prizeFormImg" alt="" />
        <div class="winningBox">
          <vue-seamless-scroll
            class="seamless-warp"
            :data="winningList"
            :class-option="classOption"
          >
            <ul class="winningList">
              <li v-for="(item, index) in winningList" :key="index">
                <span>{{ item.nickName }}</span>
                <span>{{ item.prizeName }}</span>
                <span>{{ item.createTime }}</span>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>
      <!-- 活动规则 -->
      <div class="rule">
        <img :src="imgUrl + imgList.rulesImg" alt="" />
      </div>
      <div class="support">
        <img src="../../assets/jszc.png" alt="" />
      </div>
    </div>
    <assistant @click="showFather"></assistant>
    <winningRecord
      :recordShow="recordShow"
      :recordList="recordList"
      @click="showFather"
    ></winningRecord>
    <!-- 未中奖 -->
    <noData :show="tipsShow"></noData>
  </div>
</template>

<script>
import { getImgUrl } from "../../api/index.js";
import { getDrawRecord } from "../../api/index.js";
import { wxshareNO } from "../../utils/date.js";
import { drawRecord } from "../../api/index.js";
import { getWinningRecord } from "../../api/index.js";
import { journal } from "../../api/index.js";
import assistant from "../../components/assistant/index";
import winningRecord from "../../components/winningRecord/index";
import noData from "../../components/noData/index";

export default {
  name: "authorize",
  data() {
    return {
      recordShow: false, //展示小助手
      openState: true,
      loading: true,
      winningList: [],
      prizeList: [],
      time: 3000, // 旋转时间
      rotNum: 3, // 旋转圈数基数
      imgUrl: process.env.VUE_APP_BASE_imgUrl,
      imgList: {
        pointImg: "",
        prizeFormImg: "",
        rulesImg: "",
        backgroundImg: "",
        prizePlateConfig: {
          outerImg: "",
          innnerImg: "",
        },
      },
      recordList: {
        prizeLogLists: [],
        wealLogLists: [],
        awardsLogLists: [],
      },
      base: "",
      tipsShow: false,
    };
  },
  components: {
    assistant: assistant,
    winningRecord: winningRecord,
    noData: noData,
  },
  // created() {
  //   this.$nextTick(() => {
  //     this.getWinning();
  //   });
  // },
  computed: {
    classOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: this.winningList.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        base: "22.5",
        rotate: 0,
        openTouch: false,
      };
    },
  },
  methods: {
    autoRotate(arr) {
      if (arr.length) {
        let len = arr.length;
        let base = 360 / len;
        this.base = 360 / len;
        arr.forEach((item, index) => {
          // 指的是某个奖品区域的中间 : base/2
          item.angle = 360 - (base / 2 + index * base);
          this.$set(arr, index, item);
        });
      }
      return arr;
    },
    //开始旋转 angle角度  complete回调成功函数
    startrotate() {
      console.log(this.openState);
      var that = this;
      if (this.openState) {
        this.openState = false;
        drawRecord().then(
          (res) => {
            this.oTurntable.style.webkitTransition =
              "transform " +
              this.time / 1000 +
              "s cubic-bezier(0.01,0.5,0.01,1)";
            var angle = 360 - (res.data.sequenceId * this.base - this.base / 2);
            // var angle = (res.data.sequenceId - 1) * this.base - this.base / 2;
            this.rotate = 2160 * (this.rotNum + 1) + Number(angle);
            this.oTurntable.style.webkitTransform =
              "rotate(" + this.rotate + "deg)";
            clearTimeout(this.timer);
            // 1、实物 0、电子 3、红包
            // 设置5秒后停止旋转,处理接口返回的数据
            this.timer = setTimeout(() => {
              this.openState = true;
              if (res.data.isTrue == 1 || res.data.isTrue == 0) {
                window.location.href = res.data.skipUrl;
              } else if (res.data.isTrue == 3) {
                this.$router.push({
                  path: "/redEnvelopes",
                  query: {
                    prizeId: res.data.prizeId,
                    drawLogId: res.data.drawLogId,
                  },
                });
              }
            }, this.time + 1000);
          },
          function () {
            that.openState = true;
          }
        );
      }
      // 点击转盘埋点
      var maidian = {
        userVisit: "3",
      };
      journal(maidian);
    },
    showFather() {
      this.recordShow = !this.recordShow;
      if (this.recordShow) {
        document.body.style.overflow = "hidden";
        getWinningRecord().then((res) => {
          this.recordList = res.data;
        });
        var maidian = {
          userVisit: "8",
        };
        journal(maidian);
      } else {
        document.body.style.overflow = ""; //出现滚动条
      }
    },
    getImg() {
      var that = this;
      getImgUrl({}).then((res) => {
        that.prizeList = res.data.prizePlateConfig.prizePlatConfig;
        that.imgList.outerImg = res.data.prizePlateConfig.outerImg;
        that.imgList = res.data;
        that.loading = true;
        this.autoRotate(res.data.prizePlateConfig.prizePlatConfig);
      });
    },
    getWinning() {
      getDrawRecord().then((res) => {
        this.winningList = res.data;
        this.oTurntable = document.querySelector("#turnUl");
        // 过度中属性用时5s
        this.oTurntable.style.webkitTransition =
          "transform " + this.time / 1000 + "s cubic-bezier(0.01,0.5,0.01,1)";
      });
    },
    questionHref() {
      var openId = sessionStorage.getItem("openId");
      var projectCode = sessionStorage.getItem("projectCode");
      window.location.href =
        "https://auth.h6c3.cn/questionPhase/questionPhase.html?channelId=" +
        projectCode +
        "&titleName=400005&openId=" +
        openId;
      //  window.location.href =
      //   "http://shangjietech.vip/questionPhase/questionPhase.html?channelId=" +
      //   projectCode +
      //   "&titleName=400005&openId=" +
      //   openId;
    },
    goBack() {
      this.rotate = 0;
      this.oTurntable.style.webkitTransition =
        "transform 0s cubic-bezier(0.01,0.5,0.01,1)";
      this.oTurntable.style.webkitTransform = "rotate(0deg)";
      console.log("返回");
    },
  },
  mounted: function () {
    console.log("更新啦1");
    this.$nextTick(() => {
      setTimeout(() => {
        this.getWinning(); //获取数据接口方法
      }, 500);
    });
    // this.getImg();
    wxshareNO();
    var that = this;
    history.pushState(null, null, document.URL);
    window.addEventListener(
      "popstate",
      function (e) {
        history.pushState(null, null, document.URL);
      },
      false
    );
    var browserRule = /^.*((iPhone)|(iPad)|(Safari))+.*$/;
    if (browserRule.test(navigator.userAgent)) {
      window.onpageshow = function (event) {
        if (event.persisted) {
          that.goBack();
          wxshareNO();
        }
      };
    }
    this.$nextRequest(this.getImg);
    this.$nextRequest(this.getWinning);
    // window.removeEventListener("popstate", this.goBack, false);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.turntableBox {
  width: 100%;
  /* background: url(../../assets/backgroundurl.png) no-repeat; */
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 600px;
}

.turntableBox img {
  display: block;
  width: 100%;
}

.outer {
  position: relative;
  width: 620px;
  margin: 0 auto;
  overflow: hidden;
}

.inner {
  width: 590px;
  position: absolute;
  top: 15px;
  left: 15px;
}

.pointer {
  width: 188px;
  position: absolute;
  left: 216px;
  top: 200px;
  z-index: 2;
}

.question {
  width: 140px;
  margin: 40px auto 0;
}

.winning {
  position: relative;
  width: 500px;
  margin: 0 auto;
}

.winningBox {
  position: absolute;
  width: 420px;
  height: 160px;
  left: 40px;
  top: 130px;
  overflow: hidden;
}

.winningList li {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 44px;
}

.winningList li span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.winningList li span:nth-child(2) {
  width: 180px;
}
.winningList li span:last-child {
  text-align: right;
}

.winningList li span:last-child {
  width: 120px;
  flex-shrink: 0;
}

.winningList li span:first-child {
  width: 90px;
  flex-shrink: 0;
}

.rule {
  width: 542px;
  margin: 100px auto;
  border-radius: 10px;
  background: #fff;
  padding: 30px;
  overflow: auto;
  max-height: 665px;
}

.support {
  width: 100%;
  background: #dcdcdc;
}

.prize {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.prize li {
  position: absolute;
  box-sizing: border-box;
  color: #7e250d;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  line-height: 20px;
  transform-origin: 50% 50%;
}

.prize li img {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 148px;
}
</style>
